
<div class="bg-web-primary-700">
  <web-navbar></web-navbar>
  <div class="main-page">
    <web-main-panel></web-main-panel>
    <div class="main-layout-route-content">
      <div   class="main-page-content " >
        <router-outlet></router-outlet>
      </div> 
    </div>
    <web-footer></web-footer>
  </div>

</div>
