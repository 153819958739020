<footer>
  <div class="container-fluid">
<!-- logo -->
    <div class="d-flex justify-content-center">
      <p class="footer-brand footer-brand--text">AYU</p>
    </div>

    <hr />

<!-- nav bar -->
    <div class="col-md-12">
      <ul class="footer-nav mx-auto d-flex align-items-center" style="--bs-scroll-height: 100px">
        <li class="nav-item">
          <a class="nav-link active" [routerLinkActive]="'active'" [routerLink]="['/']" fragment="work">
            Work
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" [routerLinkActive]="'active'" [routerLink]="['/']" fragment="about">
            About
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" [routerLinkActive]="'active'" [routerLink]="['/skills']" fragment="skills">
            My skills
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" [routerLinkActive]="'active'" [routerLink]="['/exp']" fragment="exp">
            Experience
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" [routerLinkActive]="'active'" [routerLink]="['/contact']" fragment="contact">
            Contact
          </a>
        </li>
      </ul>
    </div>

    <hr />

<!-- social-media bar -->
    <div class="col-md-12 footer-bottom">
      <p class="d-flex w-50 order-0 footer-text footer-text-left">
        © 2023 Diego Del Barrio Ayuso
      </p>
      <div class="footer-text text-truncate w-50 ext-truncate w-50 text-end order-2 order-md-last footer-text-right 
       footer-social-media justify-content-end">
        <a href="/" target="_blank" class="mx-2">
          <img class="media-icon" src="../../../../assets/img/social-media/github.svg" alt="Github link" />
        </a>
        <a href="/" target="_blank" class="mx-2">
          <img class="media-icon" src="../../../../assets/img/social-media/behance.svg" alt="behance link" />
        </a>
        <a href="/" target="_blank" class="mx-2">
          <img class="media-icon" src="../../../../assets/img/social-media/linkedin.svg" alt="linkedin link" />
        </a>
      </div>
      <!-- <p class="footer-text text-truncate w-50 ext-truncate w-50 text-end order-2 order-md-last footer-text-right">
        Imprint
      </p> -->
    </div>

  </div>
</footer>