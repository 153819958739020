<section class="parallax-container">

  <div parallaxItem class="background-stars">

    <div class="layer-2"></div>
    <div data-depth="1.00" class="layer-3">
      <div id="stars-1"></div>
      <div id="stars-2"></div>
      <div id="stars-3"></div>
      <div id="stars-4"></div>

    </div>

  </div>

  <div class="parallax-container--flex ">
    <p class="main-panel--text">Designing Futures<br> Where imagination <br>knows No Limits</p>


    <button class="btn p-relative" type="button"   [routerLinkActive]="'active'" [routerLink]="['/']" fragment="about">
      <strong>ABOUT ME</strong>
      <div id="container-stars">
        <div id="stars"></div>
      </div>

      <div id="glow">
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
    </button>

  </div>

</section>